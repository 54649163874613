import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import AuthState from './context/auth/AuthState';

import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import TeamsPage from './components/pages/Teams';
import SportsPage from './components/pages/Sports';
import Settings from './components/pages/Settings';

import PrivateRoute from './components/routing/PrivateRoute';

function App() {
	return (
		<AuthState>
			<Router>
				<Navbar />
				<div className='container'>
					<Switch>
						<PrivateRoute exact path='/' component={Home} />
						<PrivateRoute exact path='/teams' component={TeamsPage} />
						<PrivateRoute exact path='/sports' component={SportsPage} />
						<PrivateRoute exact path='/settings' component={Settings} />
						<Route exact path='/login' component={Login} />
						<PrivateRoute exact path='/register' component={Register} />
					</Switch>
				</div>
			</Router>
		</AuthState>
	);
}

export default App;
