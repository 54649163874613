import React from 'react';

const Home = () => {
	return (
		<p className='lead text-center mt-3'>
			Schedules area or dashboard that goes to all of the various areas
		</p>
	);
};

export default Home;
