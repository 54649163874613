import React, { Fragment } from 'react';

import Teams from '../teams/Teams';

const TeamsPage = () => {
	return (
		<Fragment>
			<Teams />
		</Fragment>
	);
};

export default TeamsPage;
