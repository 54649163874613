import React, { useReducer } from 'react';

import AuthContext from './authContext';
import authReducer from './authReducer';
import jwtDecode from 'jwt-decode';

import { LOGIN_SUCCESS, LOGOUT } from '../types';

const AuthState = props => {
	const initialState = {
		user: null,
	};

	const token = localStorage.getItem('token');
	if (token) {
		const decodedToken = jwtDecode(token);

		const isExpired = Date.now() >= decodedToken.exp * 1000;
		if (isExpired) {
			localStorage.removeItem('token');
		} else {
			initialState.user = decodedToken;
		}
	}

	const [state, dispatch] = useReducer(authReducer, initialState);

	const login = user => dispatch({ type: LOGIN_SUCCESS, payload: user });

	const logout = () => dispatch({ type: LOGOUT });

	return (
		<AuthContext.Provider value={{ ...state, login, logout }}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthState;

// try {
// 	const { exp } = jwtDecode(token);
// 	if (Date.now() >= exp * 1000) {
// 		return false;
// 	} else {
// 		return true;
// 	}
// } catch {
// 	return false;
// }
