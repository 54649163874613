import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_SPORTS_QUERY } from '../../sports/Sports';

const SportOptions = () => {
	const { loading, error, data } = useQuery(GET_SPORTS_QUERY);

	if (loading) return '';
	if (error) return '';

	return (
		<>
			{data.sports &&
				data.sports.map((sport, idx) => (
					<option key={idx} value={sport.id}>
						{sport.name}
					</option>
				))}
		</>
	);
};

export default SportOptions;
