import React, { Fragment, useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import AuthContext from '../../context/auth/authContext';
import Error from '../Error';
import Spinner from '../Spinner';

const REGISTER_USER_MUTATION = gql`
	mutation register(
		$name: String!
		$email: String!
		$password: String!
		$confirmPassword: String!
	) {
		register(
			registerInput: {
				name: $name
				email: $email
				password: $password
				confirmPassword: $confirmPassword
			}
		) {
			id
			name
			email
			createdAt
			token
		}
	}
`;

const Register = props => {
	const { login } = useContext(AuthContext);
	const [addUser, { data, loading, error }] = useMutation(
		REGISTER_USER_MUTATION
	);
	const [user, setUser] = useState({
		name: '',
		email: '',
		password: '',
		confirmPassword: '',
	});
	const onChange = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};
	const onSubmit = e => {
		e.preventDefault();
		addUser({ variables: user });
	};
	if (loading) return <Spinner />;
	if (data) {
		login(data.register);
		props.history.push('/');
	}
	return (
		<Fragment>
			<Error error={error} />
			<h1 className='text-center'>
				Account <span className='text-primary'>Register</span>
			</h1>
			<div className='row justify-content-center'>
				<div className='col-md-5 mt-3'>
					<form onSubmit={onSubmit}>
						<div className='form-group'>
							<label htmlFor='name'>Name</label>
							<input
								className='form-control'
								onChange={onChange}
								type='text'
								name='name'
								value={user.name}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='email'>Email</label>
							<input
								className='form-control'
								onChange={onChange}
								type='email'
								name='email'
								value={user.email}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='password'>Password</label>
							<input
								className='form-control'
								onChange={onChange}
								type='password'
								name='password'
								value={user.password}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='confirmPassword'>Confirm Password</label>
							<input
								className='form-control'
								onChange={onChange}
								type='password'
								name='confirmPassword'
								value={user.confirmPassword}
							/>
						</div>
						<input
							type='submit'
							value='Register'
							className='btn btn-primary btn-block mt-4'
						/>
					</form>
				</div>
			</div>
		</Fragment>
	);
};

export default Register;
