import React, { Fragment, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import ActiveTeamItem from './ActiveTeamItem';
import TeamOptions from './TeamOptions';
import SportOptions from './SportOptions';
import Error from '../../Error';
import Spinner from '../../Spinner';

const GET_ACTIVE_TEAMS_QUERY = gql`
	query GetActiveTeams {
		activeTeams {
			id
			team
			sport
			checkForConflicts
			requestUrl
			scheduleGalaxyEndpoint
			createdAt
			createdBy {
				id
				name
				email
			}
		}
	}
`;

const CREATE_ACTIVE_TEAM_MUTATION = gql`
	mutation CreateActiveTeam(
		$teamId: ID!
		$sportId: ID!
		$checkForConflicts: Boolean!
	) {
		createActiveTeam(
			teamId: $teamId
			sportId: $sportId
			checkForConflicts: $checkForConflicts
		) {
			id
			team
			sport
			checkForConflicts
			requestUrl
			scheduleGalaxyEndpoint
		}
	}
`;

const ActiveTeams = () => {
	const { data, loading, error: queryError } = useQuery(GET_ACTIVE_TEAMS_QUERY);
	const [createActiveTeam, { error: mutationError }] = useMutation(
		CREATE_ACTIVE_TEAM_MUTATION
	);
	const [activeTeam, setActiveTeam] = useState({
		team: '',
		sport: '',
		checkForConflicts: false,
	});
	const onSubmit = e => {
		e.preventDefault();
		createActiveTeam({
			variables: {
				teamId: activeTeam.team,
				sportId: activeTeam.sport,
				checkForConflicts: activeTeam.checkForConflicts,
			},
			refetchQueries: ['GetActiveTeams'],
		});
		setActiveTeam({
			team: '',
			sport: '',
			checkForConflicts: false,
		});
	};
	const onChange = e =>
		setActiveTeam({ ...activeTeam, [e.target.name]: e.target.value });
	if (loading) return <Spinner />;
	return (
		<Fragment>
			<Error error={queryError} />
			<div className='row align-items-center justify-content-between'>
				<div className='col-4'>
					<h1 className='display-4 my-3'>Active Teams</h1>
				</div>
				<div className='col-4 text-right'>
					<button
						type='button'
						className='btn btn-primary'
						data-toggle='modal'
						data-target='#addActiveTeam'
					>
						Add Active Team
					</button>
				</div>
			</div>
			{data &&
				data.activeTeams.map(team => (
					<ActiveTeamItem team={team} key={team.id} />
				))}

			<div
				className='modal fade'
				id='addActiveTeam'
				tabIndex='-1'
				role='dialog'
				aria-labelledby='addActiveTeamTitle'
				aria-hidden='true'
			>
				<form onSubmit={onSubmit}>
					<div className='modal-dialog modal-dialog-centered' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title' id='addActiveTeamTitle'>
									Add Team
								</h5>
								<button
									type='button'
									className='close'
									data-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<Error error={mutationError} />
								<div className='form-group'>
									<select
										className='form-control'
										onChange={onChange}
										name='team'
										value={activeTeam.team}
									>
										<option defaultValue>Select Team</option>
										<TeamOptions />
									</select>
								</div>
								<div className='form-group'>
									<select
										className='form-control'
										onChange={onChange}
										name='sport'
										value={activeTeam.sport}
									>
										<option defaultValue>Select Sport</option>
										<SportOptions />
									</select>
								</div>
								<div className='form-check'>
									<input
										className='form-check-input'
										type='checkbox'
										name='checkForConflicts'
										checked={!!activeTeam.checkForConflicts}
										onChange={() =>
											setActiveTeam({
												...activeTeam,
												checkForConflicts: !activeTeam.checkForConflicts,
											})
										}
										id='checkForConflicts'
									/>
									<label
										className='form-check-label'
										htmlFor='checkForConflicts'
									>
										Check for Conflicts
									</label>
								</div>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									className='btn btn-secondary'
									data-dismiss='modal'
								>
									Close
								</button>
								<input type='submit' value='Save' className='btn btn-primary' />
							</div>
						</div>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default ActiveTeams;
