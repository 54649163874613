import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AuthContext from '../context/auth/authContext';

const Navbar = ({ icon, title }) => {
	const { user, logout } = useContext(AuthContext);

	const authLinks = (
		<>
			<li className='nav-item'>
				<Link to='/' className='nav-link'>
					Home
				</Link>
			</li>
			<li className='nav-item'>
				<Link to='/teams' className='nav-link'>
					Teams
				</Link>
			</li>
			<li className='nav-item'>
				<Link to='/sports' className='nav-link'>
					Sports
				</Link>
			</li>
			<li className='nav-item'>
				<Link to='/settings' className='nav-link'>
					Settings
				</Link>
			</li>
			{/* <li className="nav-item">{user && user.name}</li> */}
			<li className='nav-item'>
				<a className='nav-link' onClick={logout} href='#!'>
					<i className='fas fa-sign-out-alt' />{' '}
					<span className='hide-sm'>Logout</span>
				</a>
			</li>
		</>
	);

	const guestLinks = (
		<>
			<li className='nav-item'>
				<Link to='/register' className='nav-link'>
					Register
				</Link>
			</li>
			<li className='nav-item'>
				<Link to='/login' className='nav-link'>
					Login
				</Link>
			</li>
		</>
	);

	return (
		<nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
			<Link to='/' className='navbar-brand'>
				<i className={icon} /> {title}
			</Link>
			<button
				className='navbar-toggler'
				type='button'
				data-toggle='collapse'
				data-target='#navbarNav'
				aria-controls='navbarNav'
				aria-expanded='false'
				aria-label='Toggle navigation'
			>
				<span className='navbar-toggler-icon'></span>
			</button>
			<div className='collapse navbar-collapse' id='navbarNav'>
				<ul className='navbar-nav ml-auto'>{user ? authLinks : guestLinks}</ul>
			</div>
		</nav>
	);
};

Navbar.defaultProps = {
	title: 'CNY High School Sports',
	icon: 'fas fa-futbol',
};

Navbar.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
};

export default Navbar;
