import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import App from './App';
import ApolloClient from 'apollo-boost';

const client = new ApolloClient({
	request: operation => {
		const accessToken = localStorage.getItem('token');
		if (accessToken) {
			operation.setContext({
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});
		}
	},
});

ReactDOM.render(
	<ApolloProvider client={client}>
		<App />
	</ApolloProvider>,
	document.getElementById('root')
);
