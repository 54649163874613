import React, { Fragment, useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import AuthContext from '../../context/auth/authContext';

import Error from '../Error';
import Spinner from '../Spinner';

const LOGIN_USER_MUTATION = gql`
	mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			id
			email
			name
			createdAt
			token
		}
	}
`;

const Login = props => {
	const { login } = useContext(AuthContext);
	const [loginUser, { data, loading, error }] = useMutation(
		LOGIN_USER_MUTATION
	);
	const [user, setUser] = useState({
		email: '',
		password: '',
	});
	const onChange = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};
	const onSubmit = e => {
		e.preventDefault();
		loginUser({ variables: user });
	};
	if (loading) return <Spinner />;
	if (data) {
		login(data.login);
		props.history.push('/');
	}
	return (
		<Fragment>
			<Error error={error} />
			<h1 className='text-center'>
				Account <span className='text-primary'>Login</span>
			</h1>
			<div className='row justify-content-center'>
				<div className='col-md-5 mt-3'>
					<form onSubmit={onSubmit}>
						<div className='form-group'>
							<label htmlFor='email'>Email</label>
							<input
								className='form-control'
								onChange={onChange}
								type='email'
								name='email'
								value={user.email}
								required
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='password'>Password</label>
							<input
								className='form-control'
								onChange={onChange}
								type='password'
								name='password'
								value={user.password}
								required
							/>
						</div>
						<input
							type='submit'
							value='Login'
							className='btn btn-primary btn-block mt-4'
						/>
					</form>
				</div>
			</div>
		</Fragment>
	);
};

export default Login;
