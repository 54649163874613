import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const TeamItem = ({ team: { id, name, schoolId } }) => {
	const [deleteTeam] = useMutation(DELETE_TEAM);
	const onClick = () => {
		deleteTeam({ variables: { teamId: id }, refetchQueries: ['GetTeams'] });
	};
	return (
		<div className='card card-body mb-3'>
			<div className='row'>
				<div className='col-md-9'>
					<h4>{name}</h4>
				</div>
				<div className='col-md-3'>
					<button onClick={onClick} type='submit' className='btn btn-danger'>
						Delete Team
					</button>
				</div>
			</div>
		</div>
	);
};

const DELETE_TEAM = gql`
	mutation DeleteTeam($teamId: ID!) {
		deleteTeam(teamId: $teamId)
	}
`;

export default TeamItem;
