import React from 'react';

import ActiveTeams from '../settings/active-teams/ActiveTeams';

const Settings = () => {
	return (
		<div className='mt-3'>
			<h2>Settings</h2>
			<hr />
			<ActiveTeams />
		</div>
	);
};

export default Settings;
