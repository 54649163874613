import React, { useContext } from 'react';

import AuthContext from '../context/auth/authContext';

const Error = ({ error }) => {
	const { logout } = useContext(AuthContext);

	if (!error || !error.message) return null;
	if (error.message === 'GraphQL error: Invalid/Expired token') {
		logout();
	}
	if (
		error.networkError &&
		error.networkError.result &&
		error.networkError.result.errors.length
	) {
		return error.networkError.result.errors.map((error, i) => (
			<div className='error' key={i}>
				<p data-test='graphql-error'>
					<strong>Shoot!</strong>
					{error.message.replace('GraphQL error: ', '')}
				</p>
			</div>
		));
	}
	return (
		<div className='error'>
			<p data-test='graphql-error'>
				<strong>Shoot!</strong>
				{error.message.replace('GraphQL error: ', '')}
			</p>
		</div>
	);
};

export default Error;
