import { LOGIN_SUCCESS, LOGOUT } from '../types';

export default (state, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			localStorage.setItem('token', action.payload.token);
			return {
				...state,
				user: action.payload,
			};
		case LOGOUT:
			localStorage.removeItem('token');
			return {
				...state,
				user: null,
			};
		default:
			return state;
	}
};
