import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const TeamItem = ({ team: { id, team, sport, checkForConflicts } }) => {
	const [deleteActiveTeam] = useMutation(DELETE_ACTIVE_TEAM);
	const onClick = () => {
		deleteActiveTeam({
			variables: { activeTeamId: id },
			refetchQueries: ['GetActiveTeams'],
		});
	};
	return (
		<div className='card card-body mb-3'>
			<div className='row'>
				<div className='col-md-9'>
					<h3>{team}</h3>
					<p>{sport}</p>
					{checkForConflicts && (
						<p className='badge badge-pill badge-info'>
							Checking for Conflicts
						</p>
					)}
				</div>
				<div className='col-md-3'>
					<button onClick={onClick} type='submit' className='btn btn-danger'>
						Delete Active Team
					</button>
				</div>
			</div>
		</div>
	);
};

const DELETE_ACTIVE_TEAM = gql`
	mutation DeleteActiveTeam($activeTeamId: ID!) {
		deleteActiveTeam(activeTeamId: $activeTeamId)
	}
`;

export default TeamItem;
