import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const SportItem = ({ sport: { id, name, season } }) => {
	const [deleteSport] = useMutation(DELETE_SPORT);
	const onClick = () => {
		deleteSport({
			variables: {
				sportId: id,
			},
			refetchQueries: ['GetSports'],
		});
	};
	return (
		<div className='card card-body mb-3'>
			<div className='row'>
				<div className='col-md-9'>
					<h4>{name}</h4>
					<p>{season}</p>
				</div>
				<div className='col-md-3'>
					<button onClick={onClick} type='submit' className='btn btn-danger'>
						Delete Sport
					</button>
				</div>
			</div>
		</div>
	);
};

const DELETE_SPORT = gql`
	mutation DeleteSport($sportId: ID!) {
		deleteSport(sportId: $sportId)
	}
`;

export default SportItem;
