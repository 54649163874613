import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_TEAMS_QUERY } from '../../teams/Teams';

const TeamOptions = () => {
	const { loading, error, data } = useQuery(GET_TEAMS_QUERY);

	if (loading) return '';
	if (error) return '';

	return (
		<>
			{data.teams &&
				data.teams.map((team, idx) => (
					<option key={idx} value={team.id}>
						{team.name}
					</option>
				))}
		</>
	);
};

export default TeamOptions;
