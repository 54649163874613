import React, { Fragment, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import TeamItem from './TeamItem';
import Error from '../Error';
import Spinner from '../Spinner';

export const GET_TEAMS_QUERY = gql`
	query GetTeams {
		teams {
			id
			name
			schoolId
			scheduleGalaxyId
		}
	}
`;

const CREATE_TEAM_MUTATION = gql`
	mutation CreateTeam(
		$name: String!
		$schoolId: Int!
		$scheduleGalaxyId: Int!
	) {
		createTeam(
			name: $name
			schoolId: $schoolId
			scheduleGalaxyId: $scheduleGalaxyId
		) {
			id
			name
			schoolId
			scheduleGalaxyId
		}
	}
`;

const Teams = () => {
	const { data, loading, error: queryError } = useQuery(GET_TEAMS_QUERY);
	const [createTeam, { error: mutationError }] = useMutation(
		CREATE_TEAM_MUTATION
	);
	const [team, setTeam] = useState({
		name: '',
		schoolId: '',
		scheduleGalaxyId: '',
	});
	const onSubmit = e => {
		e.preventDefault();
		createTeam({
			variables: {
				name: team.name,
				schoolId: parseInt(team.schoolId),
				scheduleGalaxyId: parseInt(team.scheduleGalaxyId),
			},
			refetchQueries: ['GetTeams'],
		});
		setTeam({
			name: '',
			schoolId: '',
			scheduleGalaxyId: '',
		});
	};
	const onChange = e => setTeam({ ...team, [e.target.name]: e.target.value });
	if (loading) return <Spinner />;
	return (
		<Fragment>
			<Error error={queryError} />
			<div className='row align-items-center justify-content-between'>
				<div className='col-4'>
					<h1 className='display-4 my-3'>Teams</h1>
				</div>
				<div className='col-4 text-right'>
					<button
						type='button'
						className='btn btn-primary'
						data-toggle='modal'
						data-target='#addTeam'
					>
						Add Team
					</button>
				</div>
			</div>
			{data && data.teams.map(team => <TeamItem key={team.id} team={team} />)}

			<div
				className='modal fade'
				id='addTeam'
				tabIndex='-1'
				role='dialog'
				aria-labelledby='addTeamTitle'
				aria-hidden='true'
			>
				<form onSubmit={onSubmit}>
					<div className='modal-dialog modal-dialog-centered' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title' id='addTeamTitle'>
									Add Team
								</h5>
								<button
									type='button'
									className='close'
									data-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<Error error={mutationError} />
								<div className='form-group'>
									<label htmlFor='name'>Team Name</label>
									<input
										className='form-control'
										onChange={onChange}
										type='text'
										name='name'
										value={team.name}
										required
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='schoolId'>School ID</label>
									<input
										className='form-control'
										onChange={onChange}
										type='number'
										name='schoolId'
										value={team.schoolId}
										required
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='scheduleGalaxyId'>Schedule Galaxy ID</label>
									<input
										className='form-control'
										onChange={onChange}
										type='number'
										name='scheduleGalaxyId'
										value={team.scheduleGalaxyId}
										required
									/>
								</div>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									className='btn btn-secondary'
									data-dismiss='modal'
								>
									Close
								</button>
								<input type='submit' value='Save' className='btn btn-primary' />
							</div>
						</div>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default Teams;
