import React from 'react';

import Sports from '../sports/Sports';

const SportsPage = () => {
	return (
		<div>
			<Sports />
		</div>
	);
};

export default SportsPage;
