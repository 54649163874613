import React, { Fragment, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import SportItem from './SportItem';
import Error from '../Error';
import Spinner from '../Spinner';

export const GET_SPORTS_QUERY = gql`
	query GetSports {
		sports {
			id
			sportId
			name
			season
		}
	}
`;

const CREATE_SPORT_MUTATION = gql`
	mutation CreateSport($name: String!, $sportId: Int!, $season: String!) {
		createSport(name: $name, sportId: $sportId, season: $season) {
			id
			name
			sportId
			season
		}
	}
`;

const Sports = () => {
	const { data, loading, error: queryError } = useQuery(GET_SPORTS_QUERY);
	const [createSport, { error: mutationError }] = useMutation(
		CREATE_SPORT_MUTATION
	);
	const [sport, setSport] = useState({
		name: '',
		sportId: '',
		season: '',
	});
	const onSubmit = e => {
		e.preventDefault();
		createSport({
			variables: {
				name: sport.name,
				sportId: parseInt(sport.sportId),
				season: sport.season,
			},
			refetchQueries: ['GetSports'],
		});
		setSport({
			name: '',
			sportId: '',
			season: '',
		});
	};
	const onChange = e => setSport({ ...sport, [e.target.name]: e.target.value });
	if (loading) return <Spinner />;
	return (
		<Fragment>
			<Error error={queryError} />
			<div className='row align-items-center justify-content-between'>
				<div className='col-4'>
					<h1 className='display-4 my-3'>Sports</h1>
				</div>
				<div className='col-4 text-right'>
					<button
						type='button'
						className='btn btn-primary'
						data-toggle='modal'
						data-target='#addSport'
					>
						Add Sport
					</button>
				</div>
			</div>
			{data &&
				data.sports.map(sport => <SportItem key={sport.id} sport={sport} />)}

			<div
				className='modal fade'
				id='addSport'
				tabIndex='-1'
				role='dialog'
				aria-labelledby='addSportTitle'
				aria-hidden='true'
			>
				<form onSubmit={onSubmit}>
					<div className='modal-dialog modal-dialog-centered' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title' id='addSportTitle'>
									Add Team
								</h5>
								<button
									type='button'
									className='close'
									data-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<Error error={mutationError} />
								<div className='form-group'>
									<label htmlFor='name'>Sport Name</label>
									<input
										className='form-control'
										onChange={onChange}
										type='text'
										name='name'
										value={sport.name}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='sportId'>Sport ID</label>
									<input
										className='form-control'
										onChange={onChange}
										type='number'
										name='sportId'
										value={sport.sportId}
									/>
								</div>
								<div className='form-group'>
									<label htmlFor='season'>Season</label>
									<input
										className='form-control'
										onChange={onChange}
										type='text'
										name='season'
										value={sport.season}
									/>
								</div>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									className='btn btn-secondary'
									data-dismiss='modal'
								>
									Close
								</button>
								<input type='submit' value='Save' className='btn btn-primary' />
							</div>
						</div>
					</div>
				</form>
			</div>
		</Fragment>
	);
};

export default Sports;
